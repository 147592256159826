import React, { Component } from "react";
import Alert from "sweetalert2";
import Banner from "./banner";
import AppContext from "../appContext";

class ResetTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      email: "",
      imgnum: Math.floor(Math.random() * 2) + 1,
      opacity: "reset-top",
      loading: false,
      redirectH: false,
      disabled: false,
    };

    ResetTop.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEmailStorage = this.handleChangeEmailStorage.bind(this);
  }

  handleChangeEmailStorage(email) {
    this.setState({ email: email });
    window.localStorage.removeItem("emailLogin");
  }

  componentDidMount() {
    var emailStorage = localStorage.getItem("emailLogin");
    this.handleChangeEmailStorage(emailStorage);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    var email = this.state.email.toLowerCase();
    document.getElementById("main-body").style.pointerEvents = "none";
    this.setState({
      loading: true,
      opacity: "reset-top reset-top-opacity",
      disabled: true,
    });
    this.context.showLoading("");
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ email: email }),
    };

    fetch(process.env.REACT_APP_LABS_API + "reset_password.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        document.getElementById("main-body").style.pointerEvents = "";
        this.setState({
          loading: false,
          opacity: "reset-top",
          disabled: false,
        });
        this.context.hideLoading();
        if (json.status === 200) {
          document.getElementById("reset-padlock").classList.toggle("is--disable");
          document.getElementById("reset-confirmation").classList.toggle("is--disable");
          Alert.fire({
            html: "Enviamos um link para <b>" + email + "</b>. Acesse seu e-mail para redefinir sua senha. Caso não encontre o e-mail, verifique na caixa de spam.<b></br></br>Ao redefinir a senha, não clique no botão 'Continue para Acessar'.</br>Em vez disso, acesse a página de login em <a href='https://beta.essilorconecta.com.br' class='reset-pass__alert-modal-link'>https://beta.essilorconecta.com.br/</a> e insira sua nova senha.</b></br></br><img src='https://ec2.beta.essilorconecta.com.br/modulos/img_reset_senha_provisoria.png' alt='Orientaão reset senha' class='reset-pass__alert-modal-img'></br></br>Estamos trabalhando para aplicar a correção neste fluxo.",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: 'reset-pass__alert-modal',
          });
        } else {
          Alert.fire({
            html: "O e-mail informado não está cadastrado no Essilor Conecta.",
            icon: "error",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        }
      });
  }

  goHome = () => {
    this.setState({ redirectH: true });
  };

  render() {
    const { email, redirectH } = this.state;

    if (redirectH) {
      window.location.href = "/";
    }

    return (
      <section>
        <div className={this.state.opacity}>
          <Banner></Banner>

          <div className="reset-signin js--reset-mobile">
            {/* <h1 className="reset-signin__title"></h1> */}
            <div className="reset-signin__container js--padlock" id="reset-padlock">
              <div className="reset-signin__padlock"></div>
              
              <p className="reset-signin__text">
                <h5>Informe seu e-mail cadastrado</h5>
                <br/>
              Vamos mandar um link para você redefinir sua senha de acesso.
              </p>
              <form className="reset-signin__form js--reset-form" onSubmit={this.handleSubmit}>
                <div className="reset-signin__form-row">
                  <label className="reset-signin__form-label" htmlFor="email">
                    E-mail
                  </label>
                  <input
                    className="reset-signin__form-input js--input-email"
                    name="email"
                    id="email"
                    placeholder="email@email.com.br"
                    pattern="[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                    required
                    type="text"
                    value={email}
                    onChange={this.handleChange}
                  />
                </div>
                <button className="reset-signin__form-button js--enviarReset" type="submit" title="Enviar" id="btn_enviar">
                  Enviar
                </button>
              </form>
            </div>
            <div className="reset-signin__container js--email is--disable" id="reset-confirmation">
              <div className="reset-signin__email"></div>
              <p className="reset-signin__text tp-2">
                <b>E-mail enviado com sucesso!</b>
                <br />
                Enviamos um link para <b>{email}</b>. Acesse seu e-mail para redefinir sua senha. Caso não encontre o e-mail, verifique na caixa de spam ou{" "}
                <a href="/resetpassword">clique aqui</a> para tentar novamente.
              </p>
              {/* <p className="reset-signin__text tp-3">
                                <a href="/">voltar</a>
                            </p> */}
              <button className="reset-signin__form-button" onClick={this.goHome}>
                Voltar
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ResetTop;
