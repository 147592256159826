import React, { Component } from "react";
import RolesManagementTableHeader from "./roles-management-table-header";
import Table from "react-bootstrap/Table";
import RolesManagementTableBody from "./roles-management-table-body";
import "./roles-management-table.css";
import ReactPaginate from "react-paginate";

class RolesManagementTable extends Component {
  constructor(props) {
    super();

    this.state = {
      data_tabela: [],
      pageCount: Math.ceil(props.listaUsuarios.length / 10),
      offset: 0,
      currentPage: 0
    };

    this.paginateData = this.paginateData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
componentDidMount(){
  this.state.data_tabela = this.props.listaUsuarios;
  this.state.pageCount = Math.ceil(this.props.listaUsuarios.length / 10);
  this.state.offset = 0;
  this.paginateData();
}
  componentDidUpdate(prevProps) {
    if (((prevProps.listaUsuarios.length === 0) && (this.props.listaUsuarios.length !==0)) ||  ((prevProps.listaUsuarios.length !== 0) && (this.props.listaUsuarios.length ===0))){
      this.state.data_tabela = this.props.listaUsuarios;
      this.state.pageCount = Math.ceil(this.props.listaUsuarios.length / 10);
      this.state.offset = 0;
      this.paginateData();
    }
  }

  paginateData() {
    const dataPagination = this.props.listaUsuarios.slice(this.state.offset, this.state.offset + 10);
    if (this.state.offset === 0 ){
      this.setState({currentPage : 0});
    }
    this.setState({ data_tabela: dataPagination });

  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * 10;

    this.setState({ offset: offset, currentPage: selectedPage }, this.paginateData);
    
  };
  render() {
    const { alterarPerfilUsuario, removerUsuario, listaPapeis, inativarUsuario, isInativar } = this.props;
    return (
      <>
        {this.state.data_tabela.length > 0 && (
          <>
            <div className="pagination-container">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Próximo"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={this.state.currentPage} 
              />
            </div>
            <div className="table-container">
              <Table className="text-center table-striped essilor-table">
                <>
                  <RolesManagementTableHeader isInativar={isInativar} />
                  <RolesManagementTableBody
                    listaPapeis={listaPapeis}
                    listaUsuarios={this.state.data_tabela}
                    removerUsuario={removerUsuario}
                    inativarUsuario={inativarUsuario}
                    alterarPerfilUsuario={alterarPerfilUsuario}
                    isInativar={isInativar}
                  />
                </>
              </Table>
            </div>
          </>
        )}
      </>
    );
  }
}

export default RolesManagementTable;
