import React, { Component } from "react";
import AppContext from "../../appContext";

import MultipleSelect from "../../components/selects/select-chip";
import BarGraph from "../../components/graph/double";
import Table from "../table";
import DataModule from "../../components/modulo/modulo-dados";
import { AprovacaoCupoms, DadosGraphCupons, TotalPorCategoria } from "../data/index.js";
import { filterByCriteria } from "../../components/utils/filter";
import Swal from "sweetalert2";

class AprovacaoCupom extends Component {
  constructor(props) {
    super(props);
    this.childRefs = [];
    this.state = {
      selected_option: 3,
      cnpj: "",
      filterAno: [],
      filterLab: [],
      filterMes: [],
      processedData: [],
    };
    this.childRef = React.createRef();
    this.filter = this.filter.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this);
    AprovacaoCupom.contextType = AppContext;
  }
  async componentDidMount() {
    let request = "";
    if (this.context.userSession.papelCategoria === "LABORATORIO") {
      request = {
        laboratorio: this.context.userSession.conta,
      };
    }
    this.context.showLoading("Carregando dados");
    if (this.state.processedData.length == 0) {
      const result = await AprovacaoCupoms(request);
      if(result == false ){
        Swal.fire({
          html: "Não foi possível recuperar as informações dos pedidos. Tente novamente mais tarde.",
          width: "auto",
          icon: "warning",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        }).then(result => {
                 });
      }
      this.setState({ processedData: result });
    }
    this.context.hideLoading();
  }
  cleanFilters = () => {
    this.childRefs.forEach(ref => {
      if (ref?.state.personName?.length >= 1) {
        ref.handleClear();
      }
    });

    this.setState({
      processedData: {
        filterData: this.state.processedData.dadosAPI,
        dadosAPI: this.state.processedData.dadosAPI,
        anoLabel: this.state.processedData.anoLabel,
        mes: this.state.processedData.mes,
        titulo: this.state.processedData.titulo,
      },
      filterAno: null,
      filterLab: null,
      filterMes: null,
    },
    () => {
      this.applyFilters();
    },
  );
};

  filter(data, filter) {
    if (filter === "Ano" && data) {
      this.setState({ filterAno: data }, this.applyFilters);
    } else if (filter === "Laboratório" && data) {
      this.setState({ filterLab: data }, this.applyFilters);
    } else if (filter === "Mês" && data) {
      this.setState({ filterMes: data }, this.applyFilters);
    }
  }
  async applyFilters() {
    const { filterLab, filterAno, filterCanal, filterMes, processedData, selected_option } = this.state;
    const labCNPJs = filterLab
      ? filterLab
          .map(labName => {
            const lab = this.props.laboratorios.find(l => l.label.toLowerCase() === labName.toLowerCase());
            return lab ? lab.value : null;
          })
          .filter(cnpj => cnpj !== null)
      : [];

    const combinedFilters = [
      ...labCNPJs.map(value => ({ laboratorio: value })),
      ...(filterAno || []).map(value => ({ data: value })),
      ...(filterCanal || []).map(value => ({ canal: value })),
      ...(filterMes || []).map(value => ({ data: value })),
    ];

    var filtro = filterByCriteria(processedData.dadosAPI, combinedFilters);
    var grafico = await DadosGraphCupons(filtro);
    var totalPorLab = await TotalPorCategoria(filtro);
    this.setState({
      processedData: {
        filterData: filtro,
        dadosAPI: processedData.dadosAPI,
        filterChartData: grafico,
        total: totalPorLab,
        anoLabel: processedData.anoLabel,
        mes: processedData.mes,
        titulo: processedData.titulo,
      },
    });
  }

  render() {
    const dataModule = [
      {
        id: 3,
        image: "image/modulos/logo_EssilorPromo_dashboard.png",
        value: this.state.processedData?.total?.toLocaleString("pt-BR"),
        color: "10,20,30",
        alt: "Modulo Essilor Promo",
      },
    ];
    return (
      <div>
        <div className="row mb-3 containerDados">
          <div className="col-7 dataModule-dashboardLab">
            <DataModule selected_option={this.state.selected_option} dataModule={dataModule} value={this.state.processedData.total} MultiValue />
          </div>
         
        </div>
        <div className="row">
          <div className="col-12">
            <div className="graph-container">
              {this.state.processedData?.filterChartData?.length > 0 && (
                <BarGraph titulo={"Pedidos Promocionais / Aprovações"} data={this.state.processedData?.filterChartData} />
              )}{" "}
            </div>
            
            <Table
              filterCanal={this.state.filterCanal}
              filterMes={this.state.filterMes}
              anoLabel={this.state.processedData?.anoLabel}
              filterLab={this.state.filterLab}
              selected_option={this.props.selected_option}
              laboratorio={this.props.laboratoriosNames}
              canal={this.state.processedData?.canalLabel}
              mes={this.state.processedData?.mes}
              data={this.state.processedData?.filterData}
              titulo={"Pedidos Promocionais / Aprovações"}
              filter={this.filter}
              cleanFilters={this.cleanFilters}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AprovacaoCupom;
