import React, { Component, useContext } from "react";
import "./index.css";

class ModuloDados extends Component {
  constructor(props) {
    super(props);
  }
  handleChangeOption(option) {}
  componentDidMount() {}

  render() {
    const width = this.props.widthIMG ? this.props.widthIMG : "";
    const height = this.props.heightIMG ? this.props.heightIMG : "";
    return (
      <>
        {this.props.dataModule
          .filter(item => item.id === this.props.selected_option)
          .map((item, index) => (
            <div className="containerModulo">
              <div className="cardImg" >
                <img src={item?.image} alt={item?.alt} width={item.width? item.width : ""} height={item.height ? item.height : "110px"}/>
              </div>

              {this.props.MultiValue && Array.isArray(this.props.value) ? (
                <div className="cardValue">
                  {this.props.value.map((item, index) => (
                    <div className="col-6" key={index}>
                      <div className="underlined-text"></div>
                      <div className="mx-auto value">
                        <p className="totalTitulo" style={{ color: `#${item.cor}` }}>{item.titulo}</p>
                        <p className="totalValue" style={{ color: `#${item.cor}` }}>
                          {(item.value).toLocaleString("pt-BR")}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div className="underlined-text"></div>
                  <div className="mx-auto">
                    <p className="totalValue" style={{ color: `rgb(${item.color})` }}>
                      {item?.value && (item?.value).toLocaleString("pt-BR")}
                    </p>
                  </div>
                </>
              )}
            </div>
          ))}
      </>
    );
  }
}

export default ModuloDados;
